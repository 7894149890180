import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { withRouter, InvitationType, GLGlobal } from 'gl-commonui';
import { SchoolLocale } from "../../locales/localeid";
import { InvitationsComPage } from '../../components';
import { Checkbox } from 'antd-min';
import { GSSchoolAction } from '../../util/enum';

@withRouter
export class SchoolAdminsPage extends Component<RouteComponentProps<any>> {
    clickedBtns = []
    render() {
        const { match: { params: { schoolId } } } = this.props;
        const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            <InvitationsComPage
                refId={schoolId}
                typeId={InvitationType.schoolAdmin}
                typeTitle={fmtMsg({ id: SchoolLocale.InvitationTitleSchool })}
                intl={getIntl()}
                clickedBtns={this.clickedBtns}
                addInvitationAction={GSSchoolAction.AddSchoolAdmin}
                deleteInvitationAction={GSSchoolAction.DeleteSchoolAdmin}
                sendEmailAction={GSSchoolAction.ResendSchoolAdminInvitationEmail}
            />
        )
    }
}

function getIntl() {
    return {
        Invite: SchoolLocale.InvitationInviteAdmins,
        InviteTitle: SchoolLocale.InvitationInviteTitleAdmin,
    }
}